<template>
	<header class="gnb"
		:class="this.$store.state.header.isOpen ? 'on' : ''"
	>
		<div class="c_inner">
			<div class="top_logo">
				<router-link to="/">
					<img class="ci" src="@/assets/image/common/logo.svg" alt="logo_ci" />
				</router-link>
			</div>
			<button
				@click="toggleGnb('toggle')"
				class="toggle_btn"
				:class="this.$store.state.header.isOpen ? 'on' : ''"
			>
				<div class="top"></div>
				<div class="mid"></div>
				<div class="bottom"></div>
			</button>
			<nav class="gnb_menu">
				<li>
					<div><router-link to="/IPTK">IPTK</router-link></div>
				</li>
				<li>
					<div><router-link to="/Investment">INVESTMENT</router-link></div>
				</li>
				<li>
					<div><router-link to="/Portfolio">PORTFOLIO</router-link></div>
				</li>
				<li>
					<div>
						<span
							@click="()=>this.$router.push('/withus')"
						>PROGRAM</span>
					</div>
					<ul class="menu_sub">
						<li><router-link to="/withus">WITH US</router-link></li>
						<li><router-link to="/mentoring">멘토링</router-link></li>
						<li><router-link to="/StartupEducation">창업교육</router-link></li>
						<li><router-link to="/MentorList">멘토리스트</router-link></li>
						<li><router-link to="/ProgramApply">프로그램신청</router-link></li>
					</ul>
				</li>
				<li>
					<div><router-link to="/rd">R&D</router-link></div>
				</li>
				<li>
					<div>
						<span
							@click="()=>this.$router.push('/event')"
						>INSIGHT</span>
					</div>
					<ul class="menu_sub">
						<li><router-link to="/event">EVENT</router-link></li>
						<!-- <li><router-link to="/biz_trend">BIZ_TREND</router-link></li> -->
						<li><router-link to="/innovation">혁신발전소</router-link></li>
						<li><router-link to="/letter">LETTER</router-link></li>
						<li><router-link to="/news">NEWS</router-link></li>
					</ul>
				</li>
				<li>
					<div>
						<span
							@click="()=>this.$router.push('/apply')"
						>CONTACT</span>
					</div>
					<ul class="menu_sub">
						<li><router-link to="/apply">APPLY</router-link></li>
						<li><router-link to="/partnership">PARTNERSHIP</router-link></li>
						<li><router-link to="/onlineConsulting">온라인상담</router-link></li>
					</ul>
				</li>
			</nav>
		</div>
	</header>
</template>

<script>
export default {
	mounted(){
		window.addEventListener('resize', this.headerResizer);
	},
	unmounted(){
		window.removeEventListener('resize', this.headerResizer);
	},
	methods: {
		headerResizer(){
			if(window.innerWidth > 1200){
				this.toggleGnb('remove');
			}
		},
		toggleGnb(action="toggle"){
			if(action == 'toggle'){
				this.$store.commit('header/toggle');
			} else {
				this.$store.commit('header/close');
			}
		}
	}
}
</script>

<style scoped src="@/assets/css/components/header.css">
</style>