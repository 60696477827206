export const dateFormat = {
  methods: {
    // 2021-12-31 00:00:00 식으로 오면 날짜만 짤라내는거
    dateFormat(date, format='.') {
      if (!date || typeof date != 'string') return '-';
      
      const sliced = date.slice(0, 10) // 앞에 10자리만 ex) 2021-12-31
      
      return format==='-' ? sliced : sliced.replaceAll('-', format)
    },
  },
}