import { createRouter, createWebHistory } from 'vue-router'
import store from "../store";
import Home from '../views/Home.vue'

// function setInitPage(to, next){
//   // 진입 시 페이지 page 쿼리 없거나 page<=0이면 page=1 쿼리 페이지로 교체 (최대는 fetchBoardData안에 설정해놓음)
//   if(!to.query.page || to.query.page <= 0){
//     next({ path: to.path, query: { page : 1 } })
//   } else {
//     next();
//   }
// }

const routes = [
  { // 홈
    path: '/',
    name: 'Home',
    component: Home
  },

  { // IPTK
    path: '/IPTK',
    name: 'IPTK',
    component: () => import(/* webpackChunkName: "IPTK" */ '../views/IPTK/IPTK.vue')
  },

  { // Investment
    path: '/Investment',
    name: 'Investment',
    component: () => import(/* webpackChunkName: "Investment" */ '../views/Investment/Investment.vue')
  },
  // { // Investment - 투자유치요청
  //   path: '/InvestmentRequest',
  //   name: 'InvestmentRequest',
  //   component: () => import(/* webpackChunkName: "Investment" */ '../views/Investment/InvestmentRequest.vue')
  // },

  { // Portfolio
    path: '/Portfolio',
    name: 'Portfolio',
    component: () => import(/* webpackChunkName: "Portfolio" */ '../views/Portfolio/Portfolio.vue'),
    children: [
      { // Portfolio - 포트폴리오
        path: '',
        component: () => import(/* webpackChunkName: "Portfolio" */ '../views/Portfolio/PortfolioList.vue'),
      },
      { // Portfolio/detail - 포트폴리오 상세
        path: '/detail',
        component: () => import(/* webpackChunkName: "Portfolio" */ '../views/Portfolio/PortfolioDetail.vue'),
        props: (route) => ({ which: route.query.which })
      },
    ]
  },
  
  { // Program - With Us
    path: '/withus',
    name: 'WithUs',
    component: () => import(/* webpackChunkName: "Program" */ '../views/Program/WithUs.vue')
  },
  { // Program - 멘토링
    path: '/mentoring',
    name: 'Mentoring',
    component: () => import(/* webpackChunkName: "Program" */ '../views/Program/Mentoring.vue')
  },
  { // Program - 창업교육
    path: '/StartupEducation',
    name: 'StartupEducation',
    component: () => import(/* webpackChunkName: "Program" */ '../views/Program/StartupEducation.vue')
  },
  { // Program - 멘토리스트
    path: '/MentorList',
    name: 'MentorList',
    component: () => import(/* webpackChunkName: "Program" */ '../views/Program/MentorList.vue')
  },
  { // Program - 멘토링신청
    path: '/ProgramApply',
    name: 'ProgramApply',
    component: () => import(/* webpackChunkName: "Program" */ '../views/Program/ProgramApply.vue')
  },

  { // R&D - 협업방식
    path: '/rd',
    name: 'RD',
    component: () => import(/* webpackChunkName: "R&D" */ '../views/R&D/RD.vue')
  },
  { // R&D - 연구개발과제
    path: '/research',
    name: 'Research',
    component: () => import(/* webpackChunkName: "R&D" */ '../views/R&D/RDBoard.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "R&D" */ '../components/ScrollBoard/ScrollBoard'),
        props: { menu: 'research' }
      },
      {
        path: 'detail',
        component: () => import(/* webpackChunkName: "R&D" */ '../components/ScrollBoard/DetailBoard'),
        props: true,
      },
    ],
  },
  { // R&D - 창업지원과제
    path: '/startup',
    name: 'Startup',
    component: () => import(/* webpackChunkName: "R&D" */ '../views/R&D/RDBoard.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "R&D" */ '../components/ScrollBoard/ScrollBoard'),
        props: { menu: 'support' }  // 주의 startup이 아니라 support임
      },
      {
        path: 'detail',
        component: () => import(/* webpackChunkName: "R&D" */ '../components/ScrollBoard/DetailBoard'),
        props: true,
      },
    ],
  },

  { // Insight - event
    path: '/event',
    name: 'Event',
    component: () => import(/* webpackChunkName: "Insight" */ '../views/Insight/Insight.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "Insight" */ '../components/ScrollBoard/ScrollBoard'),
        props: { menu: 'event' }
      },
      {
        path: 'detail',
        component: () => import(/* webpackChunkName: "Insight" */ '../components/ScrollBoard/DetailBoard'),
        props: true,
      },
    ],
  },
  { // Insight - BIZ_TREND
    path: '/biz_trend',
    name: 'BizTrend',
    component: () => import(/* webpackChunkName: "Insight" */ '../views/Insight/BizTrend.vue'),
  },
  { // Insight - 혁신발전소
    path: '/innovation',
    name: 'Innovation',
    component: () => import(/* webpackChunkName: "Insight" */ '../views/Insight/Insight.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "Insight" */ '../components/ScrollBoard/ScrollBoard'),
        props: { menu: 'innovation' }
      },
      {
        path: 'detail',
        component: () => import(/* webpackChunkName: "Insight" */ '../components/ScrollBoard/DetailBoard'),
        props: true,
      },
    ],
  },
  { // Insight - LETTER
    path: '/letter',
    name: 'Letter',
    component: () => import(/* webpackChunkName: "Insight" */ '../views/Insight/Insight.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "Insight" */ '../components/ScrollBoard/ScrollBoard'),
        props: { menu: 'letter' }
      },
      {
        path: 'detail',
        component: () => import(/* webpackChunkName: "Insight" */ '../components/ScrollBoard/DetailBoard'),
        props: true,
      },
    ],
  },
  { // Insight - NEWS
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "Insight" */ '../views/Insight/Insight.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "Insight" */ '../components/ScrollBoard/ScrollBoard'),
        props: { menu: 'news' }
      },
      {
        path: 'detail',
        component: () => import(/* webpackChunkName: "Insight" */ '../components/ScrollBoard/DetailBoard'),
        props: true,
      },
    ],
  },

  { // ContactUs - APPLY
    path: '/apply',
    name: 'Apply',
    component: () => import(/* webpackChunkName: "ContactUs" */ '../views/ContactUs/Apply.vue'),
  },
  { // ContactUs - PARTNERSHIP
    path: '/partnership',
    name: 'Partnership',
    component: () => import(/* webpackChunkName: "ContactUs" */ '../views/ContactUs/Partnership.vue'),
  },
  { // ContactUs - 온라인상담
    path: '/onlineConsulting',
    name: 'OnlineConsulting',
    component: () => import(/* webpackChunkName: "ContactUs" */ '../views/ContactUs/OnlineConsulting.vue')
  },

  {
    // 404 페이지
    path: "/404",
    name: "404",
    component: () => import ( /* webpackChunkName: "404" */ "../views/404.vue"),
  },
  {
    // 존재하지 않는 페이지 404로 리다이렉트
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // 맨 위로
  scrollBehavior (to, from, savedPosition) {
    // 기존 위치 존재하면 그 위치 반환
    if(savedPosition){
      return savedPosition;
    }
    // 쿼리만 다를 때는 스크롤 업 안함
    if(to.path == from.path
      && JSON.stringify(to.query) != JSON.stringify(from.query)
    ) return;

    if(to.path == '/' || to.path == from.path){
      window.scrollTo({top : 0, behavior : 'smooth'});
      return;
    }
    window.scrollTo({top : 0})
  }
})

// 페이지 변경시 로딩 시작
router.beforeEach(() => {
  store.commit('loading/startDelayLoading'); 
  store.commit('header/close'); // 다른 페이지로 넘어갈 때 헤더 닫기(뒤로가기 때문)
})
// 페이지 변경 완료시 로딩 끝냄
router.afterEach(() => {
  store.commit('loading/finishLoading');
})

export default router
