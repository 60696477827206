export default {
  namespaced: true,
  state: {
    tags: [ // 기업 태그 목록
      'ENERGY',
      'STARTUP STUDIO',
      'HEALTHCARE',
      'HCI',
      'FOOD',
      'REAL ESTATE',
      'MULTIMEDIA SERVIC',
      'BEAUTY',
      'BLOCKCHAIN',
      'IT INFRASTRUCTURE',
      'A.I',
    ], 
    company: [  // 기업 목록
      {
        tag: 'ENERGY',
        img: require('@/assets/image/sub/portfolio/corp/passorder.png'),
        img_alt: 'passorder',
        name: '페이타랩',
        content: '스마트 비대면 주문 어플리케이션',
        homepage: 'https://passorder.co.kr/',
        twitter: '',
        facebook: '',
        instagram: 'https://www.instagram.com/passorder_official/',
        naver: 'https://blog.naver.com/paytalab'
      },
      {
        tag: 'STARTUP STUDIO',
        img: require('@/assets/image/sub/portfolio/corp/jkteck.png'),
        img_alt: 'jkteck',
        name: 'JKTeck',
        content: '보드마카&차세대 빔프로젝터 스크린필름',
        homepage: 'http://jkteck.co.kr/',
        twitter: '',
        facebook: 'https://www.facebook.com/boamarkers/',
        instagram: 'https://www.instagram.com/jkteck_boa/'
      },
      {
        tag: 'ENERGY',
        img: require('@/assets/image/sub/portfolio/corp/waynehills.jpg'),
        img_alt: 'waynehills',
        name: '웨인힐스 벤처스',
        content: 'AI TTV(Text To Video) 소프트웨어 개발사 I TTV(Text To Video) 소프트웨어 개발사',
        homepage: 'https://www.waynehills.co/',
        twitter: '',
        facebook: 'https://www.facebook.com/rakudabi',
        instagram: 'https://www.instagram.com/ysuemin/'
      },
      {
        tag: 'STARTUP STUDIO',
        img: require('@/assets/image/sub/portfolio/corp/thegong.svg'),
        img_alt: 'thegong',
        name: '보라공사',
        content: '공사전문 플랫폼 서비스',
        homepage: 'https://www.thegongsa.com/',
        twitter: '',
        facebook: '',
      },
      {
        tag: 'ENERGY',
        img: require('@/assets/image/sub/portfolio/corp/ndsoft.png'),
        img_alt: 'ndsoft',
        name: 'ndsoft',
        content: '메신저 글로벌 플랫폼 개발사',
        homepage: 'http://www.nd1.co.kr/',
        twitter: '',
        facebook: '',
      },
      {
        tag: 'ENERGY',
        img: require('@/assets/image/sub/portfolio/corp/shuket.png'),
        img_alt: 'shuket',
        name: '슈켓',
        content: '마트 O2O 어플리케이션 운영 개발사',
        homepage: 'http://shuket.co.kr/',
        twitter: '',
        facebook: '',
      },
      {
        tag: 'ENERGY',
        img: require('@/assets/image/sub/portfolio/corp/bettermonday.png'),
        img_alt: 'bettermonday',
        name: '베러먼데이코리아',
        content: '현대인 라이프 스타일 플랫폼',
        homepage: 'https://bettermonday.company/',
        twitter: '',
        facebook: '',
      },
      {
        tag: 'ENERGY',
        img: require('@/assets/image/sub/portfolio/corp/dparts.svg'),
        img_alt: 'dparts',
        name: '디파츠',
        content: '플랫폼 기반의 럭셔리카 직수입/판매 및 차량공유 서비스',
        homepage: 'https://dparts.kr/',
        twitter: '',
        facebook: 'https://www.facebook.com/dparts.kr',
        instagram: 'https://www.instagram.com/d.parts/',
        naver: 'https://blog.naver.com/troyflow'
      },
      {
        tag: 'ENERGY',
        img: require('@/assets/image/sub/portfolio/corp/st1.gif'),
        img_alt: 'st1',
        name: '에스티원',
        content: '3차원 세포배양 및 재생의료제품 개발사',
        homepage: '',
        twitter: '',
        facebook: '',
      },
      {
        tag: '리버스랩',
        img: require('@/assets/image/sub/portfolio/corp/reverselab.svg'),
        img_alt: 'reverselab',
        name: '리버스랩',
        content: '공유경제 셔틀버스 셰어링 서비스',
        homepage: 'https://reverselab.co.kr/',
        twitter: '',
        facebook: '',
      },
      
    ],
  },
  mutations: {
  }
}