<template>
  <div class="c_content_container home">
    <section class="c_section main_banner">
      <div class="banner_container"
        @touchstart="bannerTouchStart"
        @touchmove="bannerTouchMove"
        @touchend="bannerTouchEnd"
      >
        <div
          :class="activeBanner == 2 ? 'active' : ''"
          class="banner_slide"
        >
          <picture>
            <source media="(max-width:1200px)" srcset="@/assets/image/home/banner/m_banner03.jpg">
            <img src="@/assets/image/home/banner/banner03.jpg" alt="banner3" />
          </picture>
          <div class="textBox">
            <p>IPTK KOREA</p>
            <h1>We create future</h1>
            <h2>
              우리는 10년 내 인류의 삶을 바꿀<br />
              스타트업을 지속 가능한 방법으로 만들어 갑니다.
            </h2>
          </div>
        </div>
        <div
          :class="activeBanner == 1 ? 'active' : ''"
          class="banner_slide"
        >
          <picture>
            <source media="(max-width:1200px)" srcset="@/assets/image/home/banner/m_banner02.jpg">
            <img src="@/assets/image/home/banner/banner02.jpg" alt="banner2" />
          </picture>
          <div class="textBox">
            <p>IPTK KOREA</p>
            <h1>We create future</h1>
            <h2>
              우리는 10년 내 인류의 삶을 바꿀<br />
              스타트업을 지속 가능한 방법으로 만들어 갑니다.
            </h2>
          </div>
        </div>
        <div
          :class="activeBanner == 0 ? 'active' : ''"
          class="banner_slide"
        >
          <picture>
            <source media="(max-width:1200px)" srcset="@/assets/image/home/banner/m_banner01.jpg">
            <img src="@/assets/image/home/banner/banner01.jpg" alt="banner01" />
          </picture>
          <div class="textBox">
            <p>IPTK KOREA</p>
            <h1>We create future</h1>
            <h2>
              우리는 10년 내 인류의 삶을 바꿀<br />
              스타트업을 지속 가능한 방법으로 만들어 갑니다.
            </h2>
          </div>
        </div>
      </div>
      <div class="banner_controller">
        <div @click="()=>this.setBannerActive(this.activeBanner+1)" class="left"></div>
        <div class="pagination">{{this.activeBanner + 1}} / 3</div>
        <div @click="()=>this.setBannerActive(this.activeBanner-1)" class="right"></div>
      </div>
    </section>
    <section class="c_section ourVision">
      <div class="c_inner">
        <h1 class="c_section_title">OUR VISION</h1>
        <div v-slide.child.vertical class="c_column_3">
          <div class="c_basic_box">
            <img src="@/assets/image/sub/iptk/vision01.svg" alt="vision01" />
            <h1>BASIC</h1>
            <p >
              경쟁이 쉽지 않은 창업 생태계에서 스타트업이 살아 남는 방법 중 하나로 '기본에 충실하자'는 마인드로 창업자의 A-Z 도약과 성장을 지원하고 있습니다.
            </p>
          </div>
          <div class="c_basic_box">
            <img src="@/assets/image/sub/iptk/vision02.svg" alt="vision02" />
            <h1>CREATIVE</h1>
            <p>
              시장의 다양한 고객을 만족시키기 위해 기존의 방법과 틀을 깨고 '새로운 방안', '새로운 접근'을 시도하고 있습니다.
            </p>
          </div>
          <div class="c_basic_box">
            <img src="@/assets/image/sub/iptk/vision03.svg" alt="vision03" />
            <h1>EXIT</h1>
            <p>
              스타트업, 액셀러레이터 성장을 위해서 한 우물에 갇혀 있지 않고 새로운 무대로의 도약을 지향해야 합니다. 우리는 그러한 모든 활동을 응원하고 지원합니다.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="c_section whatWeDo">
      <div class="c_inner">
        <h1 class="c_section_title">WHAT WE DO</h1>
        <div v-slide.child class="c_cascading_cards">
          <div @click="()=>this.$router.push('/withus')" class="c_card c_card_withus">
            <div class="img_holder">
              <img src="@/assets/image/sub/program/withus/overview01.jpg" alt="overview01">
            </div>
            <div class="textBox">
              <h1>발굴/보육</h1>
              <p>부산, 동남권 지역의 스타트업을 집중 발굴하고 성장을 위한 기본적 창업 보육시설을 제공하는 입주사업 프로그램입니다.</p>
            </div>
          </div>
          <div @click="()=>this.$router.push('/mentoring')" class="c_card c_card_withus">
            <div class="img_holder">
              <img src="@/assets/image/sub/program/withus/overview02.jpg" alt="overview02">
            </div>
            <div class="textBox">
              <h1>멘토링</h1>
              <p>1:1 멘토링 프로그램은 스타트업 창업자가 경영상 어려움에 봉착할 때 창업선배나 전담 전문가 집단을 조언을 받아 경영능력과 잠재력을 개발, 성장시키는 프로그램입니다.</p>
            </div>
          </div>
          <div @click="()=>this.$router.push('/StartupEducation')" class="c_card c_card_withus">
            <div class="img_holder">
              <img src="@/assets/image/sub/program/withus/overview03.jpg" alt="overview03">
            </div>
            <div class="textBox">
              <h1>창업교육</h1>
              <p>성공창업을 위해 예비창업자와 초기창업자들에게 경영, 법률, 회계, 경영, 인사/조직 등의 노하우를 일반적인 교육 과정으로 제공하고 창업과 성장에 필수적인 IR, BM 등에 대한 특화교육 운영하는 프로그램입니다.</p>
            </div>
          </div>
          <div @click="()=>this.$router.push('/Investment')" class="c_card c_card_withus">
            <div class="img_holder">
              <img src="@/assets/image/sub/program/withus/overview04.jpg" alt="overview04">
            </div>
            <div class="textBox">
              <h1>투자유치 사후관리</h1>
              <p>역량 있는 스타트업 발굴과 기업 심사를 통해 성장을 앞당길 수 있도록 개인투자 조합을 통한 직접투자, 클라우드 펀드, VC후속 투자 연계를 지원합니다.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="c_section documentary">
      <div class="c_inner">
        <h1 class="c_section_title">IPTK THE DOCUMENTARY</h1>
        <iframe class="documentary_video" src="https://www.youtube.com/embed/NiIdYRjuVg8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </section> -->
    <section class="c_section portfolio">
      <div class="c_inner">
        <h1 class="c_section_title">PORTFOLIOS</h1>
        <p v-slide.vertical class="c_section_description">
          초기 창업기업들을 대상으로 대표자의 사업 실현가능성과 보유역량을<br class="moblie_hide"/>
          중심으로 개인투자조합을 결성, 초기 투자를 진행하고 있습니다.
        </p>
        <swiper v-fade class="slide_container"
          v-bind="portfolioSlideOption"
        >
          <swiper-slide v-for="item in company" :key="item.name">
            <div @click="openProtfolioDetail(item.name)" class="c_company_box">
              <p class="comapny_tag">{{item.tag}}</p>
              <img class="company_logo" :src="item.img" :alt="item.img_alt" />
              <h1>{{item.name}}</h1>
              <p class="company_content">{{item.content}}</p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </section>
    <section class="c_section notice">
      <div class="c_inner">
        <div v-fade class="news">
          <h1 class="c_section_title">NEWS</h1>
          <ul class="news_list">
            <li v-for="(item, i) in newsData.slice(0, 5)" :key="i" class="textBox"
              @click="()=>openNewsDetail(item.list_pk)"
            >
              <span>{{newsData.length - i}}</span>
              <h1 class="c_ellipsis">"기술거래"의 필요성과 파급효과</h1>
              <p class="date">2022.02.18</p>
            </li>
            <div v-if="newsData.length===0" class="no_data">
              NEWS가 없습니다.
            </div>
          </ul>
          <div v-if="newsData.length>=5" class="button_wrapper">
            <button @click="()=>{this.$router.push('/news')}" class="c_button-primary more">더보기</button>
          </div>
        </div>

        <div v-fade class="event">
          <h1 class="c_section_title">EVENT</h1>
          <div class="event_list">
            <swiper v-if="eventData.length>0" v-bind="eventSlideOption">
              <swiper-slide
                v-for="(item, i) in this.eventData" :key="i"
                @click="()=>openEventDetail(item.list_pk)"
              >
                <img v-if="item.path && item.save_name"
                  :src="item.path+item.save_name" :alt="item.original_name"
                />
                <img v-else class="c_no_image" src="@/assets/image/common/no_image.svg" alt="no_image" />
                <div class="textBox">
                  <h1 class="c_ellipsis">{{item.title}}</h1>
                  <p class="date">{{dateFormat(item.enrollment_date)}}</p>
                </div>
              </swiper-slide>
            </swiper>
            <div v-else class="no_data">
              EVENT가 없습니다.
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";

import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import "swiper/swiper-bundle.min.css"
SwiperCore.use([ Pagination, Autoplay, Navigation ]);

import { fetchData } from '@/utils/fetchData';
import { dateFormat } from '@/utils/dateFormat';

export default {
  name: 'HomeView',
  components: {
    Swiper, SwiperSlide,
  },
  mixins: [ fetchData, dateFormat ],
  computed: {
    ...mapState('portfolio', ['company']),
  },
  data(){
    return{
      activeBanner: 0,
      intervalID: null,
      timeoutID: null,
      portfolioSlideOption: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 25,
        autoHeight: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          clickable : true,
        },
        breakpoints: {
          479: {
            slidesPerView: 2,  //브라우저가 479보다 클 때
          },
          599: {
            slidesPerView: 3,  //브라우저가 599보다 클 때
          },
          900: {
            slidesPerView: 4,  //브라우저가 900보다 클 때
          },
          1200: {
            slidesPerView: 5,  //브라우저가 900보다 클 때
          },
        },
      },
      eventSlideOption: {
        loop: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        pagination: {
          clickable : true,
          type: 'fraction',
        },
        navigation: true,
      },

      newsData: [],
      eventData: [],
    }
  },
  mounted(){
    this.bannerSliding();
    this.fetchNewsData();
    this.fetchEventData();
  },
  unmounted(){
    clearInterval(this.intervalID);
  },
  methods: {
    // banner 모바일 터지 이벤트 시작
    bannerTouchStart(e){
      this.touchStartPosition = e.touches[0].clientX;
    },
    bannerTouchMove(e){
      if(this.isBannerTouchMoving) return;
      
      this.isBannerTouchMoving = true;
      if(e.touches[0].clientX < this.touchStartPosition){
        this.setBannerActive(this.activeBanner-1);  // 역방향
      } else if(e.touches[0].clientX >= this.touchStartPosition){
        this.setBannerActive(this.activeBanner+1);  // 역방향
      }
    },
    bannerTouchEnd(){
      this.isBannerTouchMoving = false;
      this.touchStartPosition = null;
    },
    // banner 모바일 터지 이벤트 끝

    setBannerActive(i){ // active banner 변경
      // 0.5초 동안 막기
      if(this.timeoutID != null) return;
      this.timeoutID = setTimeout(() => {
        this.timeoutID = null;
      }, 500);

      const max = 2;
      if(i < 0){
        this.activeBanner = max;
      } else if(i > max){
        this.activeBanner = 0;
      } else {
        this.activeBanner = i;
      }
      clearInterval(this.intervalID);
      this.bannerSliding();
    },
    bannerSliding(){  // banner 자동 슬라이드
      this.intervalID = setInterval(()=>{
        this.setBannerActive(this.activeBanner+1);
      }, 4000)
    },
    openProtfolioDetail(which){
      this.$router.push({
        path: 'Portfolio/detail',
        query: { which: which },
      })
    },

    fetchNewsData(){ // event 게시판 데이터 받아오기
      this.fetchData('/getList', (data) => {
        this.newsData = data.list;
      }, {
        params : { 
          menu: 'news',
        },
        loadingEl: document.querySelector('.news_list')
      });
    },
    fetchEventData(){ // event 게시판 데이터 받아오기
      this.fetchData('/getList', (data) => {
        this.eventData = data.list;
      }, {
        params : { 
          menu: 'event',
        },
        loadingEl: document.querySelector('.event_list')
      });
    },
    openNewsDetail(pk){ // event 상세 페이지로 이동
      this.$router.push({
        path : '/news/detail',
        query : { list_pk : pk }
      });
    },
    openEventDetail(pk){ // event 상세 페이지로 이동
      this.$router.push({
        path : '/event/detail',
        query : { list_pk : pk }
      });
    },
  },
}
</script>

<style scoped src="@/assets/css/views/home.css">
</style>
