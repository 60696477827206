<template>
  <transition name="fade">
    <div v-show="this.loading" class="c_screen_filter">
      <lottie-player class="light_bulb" src="https://assets5.lottiefiles.com/packages/lf20_brzkghfk.json" background="transparent" speed="0.9"  style="width: 300px; height: 300px;" loop autoplay></lottie-player>
    </div>
    <!-- <div id="loading" v-show="this.loading"></div> -->
  </transition>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed:{
    ...mapState("loading", ['loading'] ),
  }
}
</script>

<style scoped>
.c_screen_filter{
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 100;
}
.light_bulb{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* #loading {
  background-color: rgba(255, 255, 255, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
#loading::after {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 4rem);
  left: calc(50% - 2rem);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 0.7rem solid rgba(230,230,230, 0.7);
  border-top-color: var(--primary-color);
  animation: c_spining 0.7s ease-in-out infinite;
} */

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
    transition: all .2s ease-in-out;
}
</style>