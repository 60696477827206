<template>
	<footer class="global_footer">
		<div class="c_inner">
			<div class="news_letter">
				<h1>뉴스레터 구독 신청</h1>
				<input type="email" placeholder="이메일 주소를 입력하세요." />
				<button>구독하기</button>
			</div>
			<ul class="footer_menu">
				<li>
					<router-link to="/">
						개인정보취급방침
					</router-link>
				</li>
				<li>
					<router-link to="/">
						개인정보보호규정
					</router-link>
				</li>
				<li>
					<router-link to="/">
						이메일 무단수집거부
					</router-link>
				</li>
				<li>
					<router-link to="/">
						이용약관
					</router-link>
				</li>
				<li>
					<router-link to="/">
						문의메일
					</router-link>
				</li>
			</ul>
			<div class="footer_info">
				<ul class="info_detail">
					<li>전화: 051-505-8200</li>
					<li>FAX: 051-505-8227</li>
					<li>사업자 등록번호: 607-81-94462</li>
					<li>주소: 부산광역시 연제구 법원로 32번길 19, 4층(거제동, 다성빌딩)</li>
				</ul>
				<nav class="social">
					<a href="https://search.naver.com/search.naver?sm=tab_hty.top&where=nexearch&query=%EC%95%84%EC%9D%B4%ED%94%BC%ED%85%8D%EC%BD%94%EB%A6%AC%EC%95%84&oquery=%EC%95%84%EC%9D%B4%ED%94%BC%ED%85%8D"><img src="@/assets/image/footer/naver.svg" alt="naver" /></a>
					<a href="https://twitter.com/IPTKorea"><img src="@/assets/image/footer/twitter.svg" alt="twitter" /></a>
					<a href="https://www.facebook.com/IPTKorea"><img src="@/assets/image/footer/facebook.svg" alt="facebook" /></a>
				</nav>
				<p class="copyright">&copy; {{new Date().getFullYear()}} IPTK. ALL RIGHTS RESERVED.</p>
			</div>
			<div class="ci">
				<router-link to="/">
					<img src="@/assets/image/footer/logo_footer.svg" alt="logo_footer" />
				</router-link>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
    name : "Footer"
}
</script>

<style scoped src="@/assets/css/components/footer.css">
</style>